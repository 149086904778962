import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const ErrorStyles = styled.div`
  background-color: #fff;
  #error-page {
    background-color: #fff;
    padding: 50px 0;
  }
  .to-home {
    text-align: center;
    display: block;
    padding: 20px 0 0;
    a.btn {
      font-size: 2.2rem;
      color: white;
      border: 0;
      padding: 15px 20px;
      background: var(--gold);
      @media screen and (max-width: 650px) {
        /* font-size: 18px; */
      }
    }
  }
`;

export default function ErrorPage() {
  return (
    <>
      <SEO title="Oops! This page doesn't exist." />
      <ErrorStyles>
        <section id="error-page">
          <div className="wrap">
            <p>Ah, you played a wrong note 🎵</p>
            <p>That page doesn't exist!</p>
            <div className="to-home">
              <Link className="btn" to="/">
                Return Home
              </Link>
            </div>
          </div>
        </section>
      </ErrorStyles>
      <Footer />
    </>
  );
}
